import './App.css';
import { countries } from 'countries-list';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div>
      <main>
        <header className={'header'}>
          <img src={'/bg.jpg'} alt={'bg'} />
        </header>
        <div className="container">
          <p>
            ¡Tu “pre-save” ha sido guardado exitosamente!
            <br /><br />
            Inscríbete en la forma para participar y asistir al “SEN2 KBRN 2 LISTENING PARTY” en Humacao, Puerto Rico si eres residente de Puerto Rico.
            <br /><br />
            Solo tienes que llenar la siguiente forma y estarás recibiendo tu invitación vía correo electrónico en o antes del día jueves 17 de noviembre.
          </p>
          <form action={'https://docs.google.com/forms/d/e/1FAIpQLSdxpUfcMFcX6b2npl2Fy_QwK3WgKXPNMgl2qIuEYMNBUW2Gxg/formResponse'}>
            <label htmlFor={'entry.1205021717'}>Nombre Completo / Full Name</label>
            <input type={'text'} placeholder={"Nombre/Name"} name={"entry.1205021717"} id={'entry.1205021717'} required/>
            <label htmlFor={'entry.1799021079'}>Correo Electrónico / Email</label>
            <input type={'text'} placeholder={"Email"} name={"entry.1799021079"} id={'entry.1799021079'} required />
            <label htmlFor={'entry.588393155'}>Cuidad / City</label>
            <input type={'text'} placeholder={"Pueblo/City"} name={"entry.588393155"} id={'entry.588393155'} required />
            <label htmlFor={'entry.195173890'}>País / Country</label>
            <select placeholder={"Puerto Rico"} defaultValue={"Puerto Rico"} name={"entry.195173890"} id={'entry.195173890'} required>
              {Object.entries(countries).map(([code, country]) => (
                <option key={code} value={country.name}>{country.name}</option>
              ))}
            </select>
            <button type={'submit'}>Submit</button>
            <br/>
            <br/>
            <Link to={'/reglas'}>Reglas/Rules</Link>
          </form>
        </div>
      </main>
    </div>
  );
}

export default App;
